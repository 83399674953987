import React, { useContext, useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import svgCrown from "../../assets/imgs/crown.svg";
import svgCheckGreen from "../../assets/imgs/check-green.svg";
import svgDotUpgrade from "../../assets/imgs/dot-upgrade.svg";
import svgRettangleModalPro from "../../assets/imgs/rettangle-modal-pro.svg";
import svgDotUpgradeEmpty from "../../assets/imgs/dot-upgrade-empty.svg";
import svgGift from "../../assets/imgs/gift.svg";
import { AuthContext } from "../../contexts/auth";
import MyLabel from '../myLabel';
import { Box, IconButton, Typography, useMediaQuery } from '@mui/material';
import MyImage from '../myImage';
import moment from 'moment';
import formatTryProDate from '../../utils/formatTryProDate';
import MyButton from '../myButton';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import SubscriptionButton from './subscriptionButton';

const mappingPrices = {
    "PRO Max": '39,00 €',
    "PRO Plus": '19,00 €',
    "PRO Light": '9,00 €',
}

const style = {
    container:{
        display: 'flex', 
        flexDirection: 'row', 
        width: '100%',
        '@media (max-width: 600px)': {
            flexDirection: 'column', 
        },
    },
    firstBox:{
        backgroundColor: '#f2f2f2', 
        width: '50%', 
        padding: '18px', 
        textAlign: 'center',
        '@media (max-width: 600px)': {
            width: '100%', 
        },
    },
    firstBox2:{
        position: 'relative', 
        left: '5%', 
        top: '15%', 
        padding: '0 40px',
        '@media (max-width: 600px)': {
            top: '0', 
        },
    },
    secondBox:{
        width: '50%', 
        padding: '18px',
        '@media (max-width: 600px)': {
            width: '100%', 
        },
    }
}

const TryProDialog = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const navigate = useNavigate();
    const modalTryPro = urlParams.get('try-pro')
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [planSelected, setPlanSelected] = useState('PRO Max');
    const [modalView, setModalView] = useState(0)
    const { language, user, setUser } = useContext(AuthContext);


    const plans = [
        { 
            name:'PRO Max', isBest: true, value: '39,00',
            descriptions: [
                "Chat GPT-4o to included",
                "Unlimited use of Chat GPT-4o mini (Free)",
                "1,500,000 characters of GPT-4o to use with all tools",
                "180 AI-generated images",
                "Unlimited use of the English Grammar Check & Translator Tool",
                "Autopost🔥",
            ]

        },
        { 
            name:'PRO Plus', isBest: false, value: '19,00',
            descriptions: [
                "Chat GPT-4o to included",
                "Unlimited use of Chat GPT-4o mini (Free)",
                "1,000,000 characters of GPT-4o to use with all tools",
                "45 AI-generated images",
                "Unlimited use of the English Grammar Check & Translator Tool",
            ]

        },
        { 
            name:'PRO Light', isBest: false, value: '9,00',
            descriptions: [
                "Chat GPT-4o to included",
                "10,000 characters every 2 hours of Chat GPT-4o mini",
                "500,000 characters of GPT-4o to use with all tools",
                "35 AI-generated images",
                "Unlimited use of the English Grammar Check & Translator Tool",
            ]

        },
    ]
    
    const pricingHandler = () => {
        navigate("/pricing");
    };
    const creditsHandler = () => {
        navigate("/credits");
    };
    useEffect(()=>{
        if (modalTryPro && user.has_tested){
            handleClose()
        }
    },[modalTryPro])
    
    const handleClose = () => {
        setModalView(0)
        setPlanSelected('PRO Max')
        navigate(window.location.pathname)
        setUser()
    }

    const languageMapped = {
        0:'en', 
        1: 'es',
        2: 'pt',
        3: 'it'
    }

    const planIdTrial = () =>{

        if (planSelected === 'PRO Light'){
            return process.env.REACT_APP_SUBSCRIPTION_PLAN_TRIAL_LIGHT_3DAYS_ID
        }
        else if (planSelected === 'PRO Max'){
            return process.env.REACT_APP_SUBSCRIPTION_PLAN_TRIAL_MAX_3DAYS_ID
        }
        else {
            return process.env.REACT_APP_SUBSCRIPTION_PLAN_TRIAL_PLUS_3DAYS_ID
        }
    }

    return (
        // open={!!modalTryPro}
        <Dialog fullScreen={fullScreen} open={!!modalTryPro} maxWidth="lg"
            onClose={() => handleClose()}
        >
            <IconButton
                aria-label="close"
                onClick={() => handleClose()}
                sx={{
                    position: 'absolute',
                    right: 10,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={style.container} >
                <Box sx={style.secondBox}>
                    <DialogTitle>
                        <Box display={'flex'} flexDirection={'row'}>
                            <MyLabel sx={{ fontSize: '1.4rem', fontWeight: '600' }} component='p' text={"Try AdMind PRO Free for 3 Days"} />
                            <MyImage src={svgCrown} sx={{
                                height: '30px',
                                width: '30px',
                                background: 'none',
                                marginLeft: '8px'
                            }} alt={`icon crown`} />
                        </Box>
                    </DialogTitle>

                    <DialogContent>
                        <MyLabel component='p' text={"Try AdMind PRO Description 1"} />
                        <MyLabel sx={{ fontWeight: '600' }} component='p' text={"Try AdMind PRO Description 2"} />

                    </DialogContent>
                    {modalView === 0 ? (
                        <>
                            <DialogContent>
                                <MyLabel sx={{ fontSize: '1.2rem', fontWeight: '600' }} component='p' text={"Unlock all AdMind PRO features:"} />
                            </DialogContent>

                            <DialogContent>
                                <Box display={'flex'} flexDirection={'row'}>


                                    <MyImage src={svgCheckGreen} sx={{ height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                                    <MyLabel sx={{ fontWeight: '600' }} component='p' text={"Craft Amazing Images"} />
                                </Box>
                                <MyLabel sx={{ marginLeft: '1.6rem' }} component='p' text={"Over 25+ styles with our AI Image Generator."} />

                            </DialogContent>

                            <DialogContent>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <MyImage src={svgCheckGreen} sx={{ height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                                    <MyLabel sx={{ fontWeight: '600' }} component='p' text={"Manage Social Media with Autopost"} />
                                </Box>
                                <MyLabel sx={{ marginLeft: '1.6rem' }} component='p' text={`Your AI social media assistant to automate creation, scheduling, and publishing of social media posts.`} />

                            </DialogContent>

                            <DialogContent>
                                <Box display={'flex'} flexDirection={'row'}>
                                    <MyImage src={svgCheckGreen} sx={{ height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                                    <MyLabel sx={{ fontWeight: '600' }} component='p' text={"Full Access to All AI Tools"} />
                                </Box>
                                <MyLabel sx={{ marginLeft: '1.6rem' }} component='p' text={"Utilize our Image generator, Article, ADs Generators, AI Chat (GPT-4o and GPT-4o mini), Topic Ideas, Product Name, Keywords Generators  and unlimited Grammar Check & Translation."} />
                            </DialogContent>


                        </>
                    ) :
                        <>
                        {
                            plans.map(plan=>(
                                <DialogContent>
                                    <Box display={'flex'} flexDirection={'row'}>
                                        <MyImage onClick={() => setPlanSelected(plan.name)} src={planSelected === plan.name ? svgDotUpgrade : svgDotUpgradeEmpty} sx={{ cursor: 'pointer', height: '15px', width: '15px', background: 'none', marginTop: '0.4rem', marginRight: '0.6rem' }} alt={`check`} />
                                        <Typography sx={{ fontWeight: '600' }} component='p' >{plan.name}</Typography>
                                        {
                                            plan.isBest ?
                                            <Box style={{ marginLeft:'0.8rem', padding:'4px 16px 2px 16px', borderRadius:'18px', backgroundImage: `url(${svgRettangleModalPro})`}}>
                                                <MyLabel sx={{fontSize:'0.7rem', color:'white'}} text={'BEST OPTION'} />
                                            </Box> : <></>
                                        }
                                    </Box>
                                    <Typography sx={{ marginLeft: '1.6rem', color: '#838383' }} component='p' >{plan.value} €</Typography>
                                    {
                                        plan.descriptions.map(description=>(
                                            <MyLabel sx={{ marginLeft: '1.6rem' }} component='p' text={description} />
                                        ))
                                    }

                                </DialogContent>
                            ))
                        }

                        </>}


                </Box>

                <Box sx={style.firstBox}>
                    <Box sx={style.firstBox2}>
                        <MyImage src={svgGift} sx={{ height: '80px', width: '80px', background: 'none', }} alt={`gift`} />
                        <DialogTitle id="alert-dialog-title">
                            <Box sx={{display:'flex', '@media (max-width: 600px)': {display: 'block'},}}>
                                <MyLabel sx={{ fontSize: '1.8rem', fontWeight: '600' }} component='p' text={"FREE Trial AdMind PRO"} />
                                <MyLabel sx={{ marginLeft:'5px',color:'red', fontSize: '1.8rem', fontWeight: '600' }} component='p' text={"MAX"} noTranslate={true}/>
                            </Box>
                            <MyLabel sx={{ fontWeight: '600' }} component='p' text={"You can cancel at any time!"} />
                        </DialogTitle>
                        {modalView === 0 ? (
                            <>
                                <DialogContent sx={{ marginTop: '1.4rem', }}>
                                    <Typography>
                                        <FormattedMessage id={'Your subscription will start on'} />
                                        {' '}
                                        {formatTryProDate(moment().add('3', 'day'), languageMapped[language?.value])}
                                        {' '}
                                        <FormattedMessage id={'only if you don’t cancel it before'} />
                                    </Typography>
                                </DialogContent>
                              
                            </>
                        ) :
                            (
                                <>
                                    <DialogContent sx={{ marginTop: '1.4rem', textAlign: 'left' }}>
                                        <Typography sx={{ fontSize: '1.2rem', fontWeight: 600 }}>
                                            {planSelected}
                                        </Typography>

                                        <Box display={'flex'} marginTop={'0.5rem'}>
                                            <Box display={'flex'} justifyContent={'flex-start'} width={'60%'}>
                                                <FormattedMessage id='Pay on'/>{' '}
                                                {formatTryProDate(moment().add('3', 'day'), languageMapped[language?.value])}
                                            </Box>
                                            <Box display={'flex'} justifyContent={'flex-end'} width={'40%'}>
                                                {mappingPrices[planSelected]}
                                            </Box>
                                        </Box>
                                        <Box display={'flex'} marginTop={'0.5rem'}>
                                        <Box display={'flex'} justifyContent={'flex-start'} width={'60%'}>
                                            <FormattedMessage id='Pay today'/>{' '}  <Box style={{ color: "green", marginLeft: '0.5rem', fontWeight: '800' }}>
                                                <FormattedMessage id='(3 days FREE)'/>{' '}
                                            </Box>
                                            </Box>
                                           
                                            <Box display={'flex'} justifyContent={'flex-end'} width={'40%'}>
                                                0 €
                                            </Box>
                                        </Box>
                                    </DialogContent>
                                </>
                            )
                        }
                        {
                            user.in_update ? <Box 
                                    sx={{
                                    padding: '5px',
                                    color: '#856404',
                                    fontSize: '13pt',
                                    boxShadow: 'rgba(0, 0, 0, 0.36) 10px 7px 10px 0px',
                                    background: '#fff3cd',
                                    border: '1px solid #f5dfc6',
                                    textAlign: 'center',
                                    }}
                                    >
                                    <MyLabel component="h3" text="Your user is being updated." />
                                    <MyLabel component="h3" text="Wait a few minutes." />
                                </Box>
                            :
                            modalView === 0 ?
                            <MyButton
                                    onClick={modalView === 0 ? () => setModalView(user.in_update ? 0 : 1) : () => console.log('submitting...')}
                                    text='Start your FREE Trial now'
                                    variant="contained"
                                    size="medium"
                                    sx={{
                                        marginTop: '1.4rem',
                                        height: '32pt',
                                        marginRight: '10px',
                                        backgroundColor: '#6366F1',
                                        textTransform: 'capitalize !important',
                                        ':hover': {
                                            backgroundColor: '#9193F5',
                                        },

                                    }}
                                />
                            : <SubscriptionButton plan_id={planIdTrial()} closeModal={()=>handleClose()} />
                        }
                                    <DialogContent >
                                        <Typography>
                                            <FormattedMessage id={'Cancel at any time. Your first payment will be due on'} />
                                            {' '}
                                            {formatTryProDate(moment().add(3, 'day'), languageMapped[language?.value])},
                                            {' '}
                                            <FormattedMessage id={'but you can cancel at any point before this date with no penalty.'} />
                                        </Typography>
                                    </DialogContent>
                    </Box>
                </Box>
            </Box>
        </Dialog>

    )
}


export default TryProDialog
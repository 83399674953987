
import React from 'react';
import toast from "react-hot-toast";
import {useDropzone} from 'react-dropzone';
import { FormattedMessage } from "react-intl";
import Box from '@mui/material/Box';

import MyImage from "../myImage"
import MyLabel from "../myLabel"
import svg from "../../assets/imgs/update.svg";

import { style } from "./styles";


const DragDropImage = ({image, setFile}) => {

    const validateFile = (file) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }


    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(async  file => {
                console.log(file)
               if (validateFile(file)) { 
                setFile(file)
                image.set(URL.createObjectURL(file))
               } else { toast.error(<FormattedMessage id='Supports jpg, png and gif' />)}
            })}});

      return (
        <Box sx={style.container} {...getRootProps({className: 'dropzone'})}>
                <MyImage sx={style.icon} src={svg} alt={'update icon'}  />
                <MyLabel component='p' text='Drop your image here or' sx={style.title}  />
                <Box sx={{textAlign:'center', border:'1px solid grey', padding:'0.4rem 2rem', borderRadius:'12px', margin:'0.5rem', width:'90%'}}>
                    <MyLabel text={'upload'}/>
                </Box>
                    <input {...getInputProps()} />
            
            <MyLabel component='p' text='Supports jpg, png and gif' sx={style.title2}  />
            
        </Box>
    );
}

export default DragDropImage;